/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FullStateI } from '../../types/All.type';
import { getTerminals } from '../../store/UKOps/FieldNoms';
import { ISelectOptions } from '../../types/Common.type';

type GetTerminalsType = (getCall?: boolean) => {
  loadingTerminals: boolean;
  terminals: ISelectOptions[];
};

const useGetTerminals: GetTerminalsType = (getCall = true) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(
    (state: FullStateI) => state.FieldNoms.terminal
  );

  useEffect(() => {
    if (data.length === 0 && !loading && getCall) {
      dispatch(getTerminals());
    }
  }, [data]);

  return { loadingTerminals: loading, terminals: data };
};

export default useGetTerminals;
