import React from 'react';
import IAppRoute from './AppRoute.type';
import routePaths from './routepaths';
import FNReconcileMainComponent from '../pages/FieldNomsReconcile';

const FNReconcileRoutes: IAppRoute[] = [
  {
    path: routePaths.reconcile,
    element: <FNReconcileMainComponent />,
  },
];

export default FNReconcileRoutes;
