export const applicationName = {
  dashboard: 'Dashboard',
  upcomingPrismaAuctions: 'Upcoming-Prisma-Auctions',

  /** Shell Energy Retail (remaining) */
  firstUtility: 'FirstUtility',
  variable: 'FirstUtility',

  /** Ops Checklist */
  checkListShift: 'CheckList-Shift', // Gas Ops Shift
  checkListAddTask: 'CheckList-Add Task', // Gas Add Task
  commercialOpsAdd: 'Commercial-Opportunity-Add', // Gas ops day-night shift
  commercialOpsList: 'Commercial-Opportunity-List', // Add Task inside Add task
  checklistDealAcualisation: 'Checklist-Deal-Actualisation',
  // checklistDealAcualisationPower: 'Checklist-Deal-Actualisation-Power',
  checklistPowerPortfolio: 'Checklist-Power-Portfolio', // Power portfolio
  checklistShiftWholesaleGas: 'Checklist-Wholesale-Gas', // wholesale (Gas) Ops
  checklistShiftWholesalePower: 'Checklist-Wholesale-Power', // Power Ops (planning to rename thids to checklist)
  checklistPowerShift: 'Checklist-Power-Operation-Shift', // Power Shift
  checklistRenewableShift: 'Checklist-Renewables-Operation-Shift', // Renewable ops
  checklistAuditTaskReport: 'Checklist-Audit-Task-Report', // report

  checklistTradeControl: 'Trade-Control-User', // checklist trade control
  checklistConfirmations: 'Confirmations',
  /** Conversion Calc */
  conversionCalcFormulaBuilder: 'Conversion-Calc-Formula-Builder', // Formula Builder
  conversionCalc: 'Conversion-Calc', // convert
  weightedAverageCalculator: 'Weighted-Average-Cal', // weighted average

  /** Credentials Store */
  credentialStore: 'Credential-Store',

  /** Trade Price */
  tradePrice: 'Price-Api',

  /**  Contact List */
  contactList: 'Contact-List',

  /** Position Handover */
  positionHandover: 'Position-Handover',

  positionHandoverOperator: 'position-handover-operator',
  positionHandoverTrader: 'position-handover-trader',

  /** SENA */
  senaPipelineAllocation: 'SENA-Pipeline-Allocation',
  senaPasswordHub: 'SENA-Password-Hub',
  epsqCalculator: 'EPSQ-Calc',

  /** Gas Trading P/L */
  pnlDashboard: 'PnL-Dashboard',

  /** User Administration */
  userAdministration: 'User-Administration',

  /** Capacity Triff */
  capacityTariff: 'Capacity-Tariff',

  /** GTO Logs */
  gtoLogs: 'Gto-Logs',

  /** Power Logs */

  PowerOpsLog: 'Power-Shift-Log',

  capacityManagementTool: 'Capacity-Management-Tool', // CMT App

  keyMetrics: 'KeyMetrics', // not using as such

  // weekendSetupTasks: 'Weekend-Setup-Task'

  /** Genesis */
  genesis: 'Genesis',
  genesisAdmin: 'Genesis-Admin',

  /** Storage Portfolio */

  storagePortfolio: 'Storage-Portfolio',
  storagePortfolioAdmin: 'Storage-Portfolio-Admin',

  /** IBT */
  ibt: 'IBT',
  ibtAdmin: 'IBT-Admin',
};

export const userRole = {
  readOnly: 'ReadOnly',
  readWrite: 'ReadWrite',
  noAccess: 'NoAccess',
};
