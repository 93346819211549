import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const FNReconcileComp = React.lazy(
  () => import('../../components/FNReconcile')
);

const FNReconcileMainComponent: React.FC = () => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.genesis}>
      <FNReconcileComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default FNReconcileMainComponent;
