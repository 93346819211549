import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routepaths from './routepaths';
import { FullStateI } from '../types/All.type';
import Layout from '../layout';
import RequireAuth from '../components/Auth/RequireAuth';
import Login from '../pages/Login';
import Unauthorized from '../pages/Unauthorized';
import UnauthorizedCMT from '../pages/UnauthorizedCMT';
import Forbidden from '../pages/Forbidden';
import Error from '../pages/Error';
import Authenticate from '../pages/Authenticate';
import Logout from '../pages/Logout';
import Home from '../pages/Home';
import Dashboard from '../pages/Dashboard';
import FirstUtilityRoutes from './FirstUtilityRoutes';
import OpsChecklistRoutes from './OpsChecklistRoutes';
import ChartsRoutes from './ChartsRoutes';
import ConversionCalculatorRoutes from './ConversionCalculatorRoutes';
import CredentialsStoreRoutes from './CredentialsStoreRoutes';
import TradePriceRoutes from './TradePriceRoutes';
import CMTRoutes from './CMTRoutes';
import ContactListRoutes from './ContactListRoutes';
import PositionHandoverRoutes from './PositionHandoverRoutes';
import SENARoutes from './SENARoutes';
import PNLRoutes from './PNLRoutes';
import UserAdministrationRoutes from './UserAdministrationRoutes';
import CapacityTariffRoutes from './CapacityTariffRoutes';
import IAppRoute from './AppRoute.type';
import GTOLogRoutes from './GTOLogRoutes';
import PowerShiftRoutes from './PowerShiftRoutes';
import UKOpsRoutesRoutes from './UKOpsRoutes';
import StoragePortfolioRoutes from './StoragePortfolioRoutes';
import BeachSwapsRoutes from './beachSwapsRoutes';
import IBTRoutes from './IBTRoutes';
import FNReconcileRoutes from './FNReconcile';

const allRoutes: IAppRoute[] = [
  ...FirstUtilityRoutes,
  ...OpsChecklistRoutes,
  ...ChartsRoutes,
  ...ConversionCalculatorRoutes,
  ...CredentialsStoreRoutes,
  ...TradePriceRoutes,
  ...CMTRoutes,
  ...ContactListRoutes,
  ...PositionHandoverRoutes,
  ...SENARoutes,
  ...PNLRoutes,
  ...UserAdministrationRoutes,
  ...CapacityTariffRoutes,
  ...GTOLogRoutes,
  ...PowerShiftRoutes,
  ...UKOpsRoutesRoutes,
  ...StoragePortfolioRoutes,
  ...BeachSwapsRoutes,
  ...IBTRoutes,
  ...FNReconcileRoutes,
];

function AppRouter(): ReactElement {
  const userState = useSelector((state: FullStateI) => state.User);
  return (
    <Routes>
      <Route path={routepaths.login} element={<Login />} />
      <Route path={routepaths.authenticate} element={<Authenticate />} />
      <Route path={routepaths.unauthorized} element={<Unauthorized />} />
      <Route path={routepaths.unauthorizedCMT} element={<UnauthorizedCMT />} />
      <Route path={routepaths.login} element={<Login />} />
      <Route path={routepaths.logout} element={<Logout />} />

      <Route element={<Layout />}>
        <Route
          path={routepaths.home}
          element={userState.isAuthorized ? <Dashboard /> : <Home />}
        />
        <Route path={routepaths.dashboard} element={<Dashboard />} />

        <Route element={<RequireAuth />}>
          {allRoutes.map(({ path, element }) => (
            <Route key={`route_${path}`} path={path} element={element} />
          ))}
        </Route>
        <Route path={routepaths.error} element={<Error />} />
        <Route path={routepaths.forbidden} element={<Forbidden />} />
      </Route>

      <Route path='*' element={<span>404</span>} />
    </Routes>
  );
}

export default AppRouter;
